import { render, staticRenderFns } from "./OrganInfo.vue?vue&type=template&id=67914fe9&scoped=true"
import script from "./OrganInfo.vue?vue&type=script&lang=js"
export * from "./OrganInfo.vue?vue&type=script&lang=js"
import style0 from "./OrganInfo.vue?vue&type=style&index=0&id=67914fe9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67914fe9",
  null
  
)

export default component.exports